<template>
    <v-form>
        <p class="text-h6 font-weight-bold teal--text text--darken-1">
            基本情報
        </p>
        <v-container
            fluid
            class="bl_formWrapper"
        >
            <v-row
                class="px-4 bl_formWrapper_form"
                align="baseline"
                no-gutter
            >
            <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold"
            >
                建物名称
            </v-col>
            <v-col>
                <v-text-field
                class="cusInput buildingName"
                hide-details="auto"
                color="grey"
                outlined
                dense
                v-model="buildingName"
                :error-messages="getErrors('basicInformation.buildingName','建物名称')"
                @input="$v.basicInformation.buildingName.$touch()"
                @blur="$v.basicInformation.buildingName.$touch()"
                >
                </v-text-field>
            </v-col>
            </v-row>
            <v-row class="px-4 bl_formWrapper_form" align="baseline" no-gutter>
            <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold">
                地域の区分
                </v-col>
            <v-col>
                <v-row dense>
                <v-radio-group
                    row
                    dense
                    hide-details
                    v-model="chiki"
                >
                    <v-radio
                        v-for="n in 8"
                        :key="n"
                        :label="`${n}地域`"
                        :value=n
                        color="#4ACAA0"
                    ></v-radio>
                </v-radio-group>
                </v-row>
                <v-row dense>
                <v-card
                    outlined
                    rounded
                    width=100%
                    color="#F2FBFA"
                    class="my-2 mr-3">
                    <v-card-text>
                        <div class="float-left mb-1">
                        <v-icon small left color="#118C66">mdi-information</v-icon>
                        </div>
                        <div>
                        <span
                            class="teal--text text--darken-1 text-body-2 font-weight-bold"
                        >
                            令和1年11月16日に新しい地域区分が施行されました。
                        </span>
                        <br/>
                        <a
                            href="https://house.lowenergy.jp/dictionary/basic#env_chiki"
                            target="_blank"
                            class="text-decoration-none"
                        >
                            地域の区分は、こちらを参考に選択します。
                        </a>
                        </div>
                    </v-card-text>
                </v-card>
                </v-row>
            </v-col>
            </v-row>
            <v-row
            class="px-4 bl_formWrapper_form"
            align="baseline"
            no-gutter>
                <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold"
                >
                住棟全体の住戸数
                </v-col>
                <v-col>
                <v-text-field
                    class="cusInput"
                    hide-details="auto"
                    color="grey"
                    outlined
                    dense
                    suffix="戸"
                    v-model="householdTotalNum"
                    :error-messages="getErrors('basicInformation.householdTotalNum','住棟全体の住戸数')"
                    @input="$v.basicInformation.householdTotalNum.$touch()"
                    @blur="$v.basicInformation.householdTotalNum.$touch()"
                >
                </v-text-field>
                </v-col>
            </v-row>
            <v-row
            class="px-4 bl_formWrapper_form"
            align="baseline"
            no-gutter
            >
            <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold"
            >
                入力方法
            </v-col>
            <v-col>
                <v-row dense>
                <v-radio-group
                    hide-details
                    dense
                    row
                    v-model="evaluationMethod"
                >
                    <v-radio
                        label="当該住戸の仕様を用いて評価する(標準計算) "
                        :value="0"
                        color="#4ACAA0"
                    ></v-radio>
                    <v-radio
                        label="フロアごとの代表的な仕様を用いて評価する(フロア入力法)"
                        :value="1"
                        color="#4ACAA0"
                    ></v-radio>
                </v-radio-group>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            </v-row>
            <v-row
            class="px-4 bl_formWrapper_form"
            align="baseline"
            no-gutter
            >
            <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold"
            >
                外皮性能の評価方法
            </v-col>
            <v-col>
                <v-row dense>
                <v-radio-group
                    hide-details
                    dense
                    row
                    v-model="envEvaluationMethod"
                >
                    <v-radio
                    label="住戸単位"
                    :value="0"
                    :disabled="evaluationMethod == 1"
                    color="#4ACAA0"
                    ></v-radio>
                    <v-radio
                    label="住棟単位平均"
                    :value="1"
                    color="#4ACAA0"
                    ></v-radio>
                </v-radio-group>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            </v-row>
            <v-row
            class="px-4 bl_formWrapper_form"
            align="baseline"
            no-gutter
            >
            <v-col
                cols=3
                class="el_formLabel teal--text text--darken-1 text-body-2 font-weight-bold"
            >
                一次エネの評価方法
            </v-col>
            <v-col>
                <v-row dense>
                <v-radio-group
                    hide-details
                    dense
                    row
                    v-model="commonAreaType"
                    >
                    <v-radio
                        label="住戸部分＋共用部分"
                        :value="true"
                        color="#4ACAA0"
                    ></v-radio>
                    <v-radio
                        label="住戸部分"
                        :value="false"
                        color="#4ACAA0"
                    ></v-radio>
                </v-radio-group>
                </v-row>
                <v-row>
                </v-row>
            </v-col>
            </v-row>

        </v-container>
        <v-row class="pl-5 pr-5">
            <v-btn
            to="/"
            rounded-lg
            color="#A0A0A0"
            width="150"
            height="60"
            class="mt-15 white--text font-weight-bold text-h6"
            >
            <v-icon large>mdi-chevron-left</v-icon>
            戻る
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
            @click="toHouseholdUpload"
            color="#025F59"
            width="250"
            height="60"
            class="mt-15 ml-5 white--text font-weight-bold text-h6"
            >
            アップロード画面
            <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
        </v-row>
    </v-form>
</template>
<script>
// バリデーション
import { required } from "vuelidate/lib/validators"
import { mapGetters, mapActions } from 'vuex'

// 正の数 - バリデーションルール
const positiveInt = val=> {
    if (typeof val === 'undefined' || val === null || val === '') {
        return true
    }
    return /^([1-9]\d*)$/.test(val) && val <= 1000
}

export default {
    // バリデーションルール
    validations: {
        // 基本情報
        basicInformation: {
          buildingName: { required },
          householdTotalNum: { required, positiveInt }
        }
    },
    computed: {
        ...mapGetters('basicInfo', {basicInformation:'getBasicInfo'}),
        buildingName: {
            get () {
                return this.basicInformation.buildingName
            },
            set (value) {
                this.updateBasicInfo({buildingName:value})
            }
        },
        chiki: {
            get () {
                return this.basicInformation.chiki
            },
            set (value) {
                this.updateBasicInfo({chiki:value})
            }
        },
        householdTotalNum: {
            get () {
                return this.basicInformation.householdTotalNum
            },
            set (value) {
                this.updateBasicInfo({householdTotalNum:value})
            }
        },
        evaluationMethod: {
            get () {
                return this.basicInformation.evaluationMethod
            },
            set (value) {
                this.updateBasicInfo({evaluationMethod:value})
            }
        },
        envEvaluationMethod: {
            get () {
                return this.basicInformation.envEvaluationMethod
            },
            set (value) {
                this.updateBasicInfo({envEvaluationMethod:value})
            }
        },
        commonAreaType: {
            get () {
                return this.basicInformation.commonAreaType
            },
            set (value) {
                this.updateBasicInfo({commonAreaType:value})
            }
        },
       //エラーメッセージを返す
       getErrors() {
           return (input, inputName) => {
               const errors = []
               if (!eval("this.$v."+ input).$dirty) return errors
               // 必須
               !eval("this.$v."+ input).required && errors.push(inputName + "を入力して下さい。")
               // 整数
               if (eval("this.$v."+ input).positiveInt !== undefined) {
                   !eval("this.$v."+ input).positiveInt && errors.push(inputName + "を正しく入力して下さい。")
               }
               return errors
            }
        }
    },
    watch: {
        evaluationMethod(val) {
            if(val == 1) this.envEvaluationMethod = 1
        }
    },
    methods: {
        ...mapActions('basicInfo', ['updateBasicInfo']),
        // 住戸部分のアップロード画面に遷移
        toHouseholdUpload() {
           this.$v.basicInformation.$touch()
           if(!this.$v.basicInformation.$invalid) {
               this.$router.push('household-upload')
           }
        }
    }
}
</script>
<style scoped>
.cusInput{
    border-radius: 4px;
}
@media (min-width: 600px) {
    .cusInput{
        max-width: 30%;
    }
    .buildingName {
        max-width: 50%;
    }
}
</style>